import logo from './logo.svg';
import './App.css';
import React from 'react';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
} from 'react-router-dom';

function App() {
	const [data, setData] = React.useState([]);
	const [email, setEmail] = React.useState('');
  const [cData,setCData] = React.useState({url:"",name:""});
	return (
		<div className="App">
			{/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
			<Switch>
				<Route
					exact
					path="/login"
					render={() => (
						<div>
							<SignIn
								data={data}
								setData={setData}
								email={email}
								setEmail={setEmail}
								cData={cData}
								setCData={setCData}
							/>
						</div>
					)}
				/>
				<Route
					exact
					path="/dashboard"
					render={() => (
						<div>
							<Dashboard
								data={data}
								setData={setData}
								email={email}
								setEmail={setEmail}
								cData={cData}
								setCData={setCData}
							/>
						</div>
					)}
				/>
				<Redirect from="/" to="/login" />
			</Switch>
		</div>
	);
}

export default App;
