// import React from "react";
// import Link from "@material-ui/core/Link";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Title from "./Title";

// // Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//   return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//   createData(
//     0,
//     "16 Mar, 2019",
//     "Elvis Presley",
//     "Tupelo, MS",
//     "VISA ⠀•••• 3719",
//     312.44
//   ),
//   createData(
//     1,
//     "16 Mar, 2019",
//     "Paul McCartney",
//     "London, UK",
//     "VISA ⠀•••• 2574",
//     866.99
//   ),
//   createData(
//     2,
//     "16 Mar, 2019",
//     "Tom Scholz",
//     "Boston, MA",
//     "MC ⠀•••• 1253",
//     100.81
//   ),
//   createData(
//     3,
//     "16 Mar, 2019",
//     "Michael Jackson",
//     "Gary, IN",
//     "AMEX ⠀•••• 2000",
//     654.39
//   ),
//   createData(
//     4,
//     "15 Mar, 2019",
//     "Bruce Springsteen",
//     "Long Branch, NJ",
//     "VISA ⠀•••• 5919",
//     212.79
//   ),
// ];

// function preventDefault(event) {
//   event.preventDefault();
// }

// const useStyles = makeStyles((theme) => ({
//   seeMore: {
//     marginTop: theme.spacing(3),
//   },
// }));

// export default function Orders() {
//   const classes = useStyles();
//   return (
//     <React.Fragment>
//       <Title>Recent Orders</Title>
//       <Table size="small">
//         <TableHead>
//           <TableRow>
//             <TableCell>Date</TableCell>
//             <TableCell>Name</TableCell>
//             <TableCell>Ship To</TableCell>
//             <TableCell>Payment Method</TableCell>
//             <TableCell align="right">Sale Amount</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow key={row.id}>
//               <TableCell>{row.date}</TableCell>
//               <TableCell>{row.name}</TableCell>
//               <TableCell>{row.shipTo}</TableCell>
//               <TableCell>{row.paymentMethod}</TableCell>
//               <TableCell align="right">{row.amount}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//       <div className={classes.seeMore}>
//         <Link color="primary" href="#" onClick={preventDefault}>
//           See more orders
//         </Link>
//       </div>
//     </React.Fragment>
//   );
// }

import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { PartyModeSharp } from "@material-ui/icons";

const columns = [
  { field: "id", headerName: "ID" },
  { field: "name", headerName: "Name", width: 170 },
  { field: "email", headerName: "Email", width: 300 },
  {
    field: "resume",
    headerName: "Resume",
    width: 150,
    renderCell: (params) => (
      <strong>
        {/* {params.value} */}
        <a href={params.value} target="_blank">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            // onclick={() => window.open(params.value, '_blank')}
          >
            open
          </Button>
        </a>
      </strong>
    ),
  },
  // { field: 'resume', headerName: 'resume link', width: 300 },
  { field: "domain", headerName: "Domain Applied", width: 250 },
  // {
  // 	field: 'videoResume',
  // 	headerName: 'video Resume',
  // 	width: 150,
  // },
  {
    field: "videoResume",
    headerName: "Video Resume",
    width: 250,
    renderCell: (params) => (
      <strong>
        {/* {params.value} */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          // onclick={() => window.open(params.value, '_blank')}
        >
          <a href={params.value} target="_blank">
            open
          </a>
        </Button>
      </strong>
    ),
  },

  {
    field: "roll",
    headerName: "Roll",
    // type: 'number',
    width: 170,
  },
  {
    field: "cgpa",
    headerName: "CGPA",
    type: "number",
    width: 120,
  },
  // {
  // 	field: 'no',
  // 	headerName: 'ph no',
  // 	type: 'number',
  // 	width: 120,
  // },
  {
    field: "gender",
    headerName: "Gender",
    type: "number",
    width: 170,
  },
  {
    field: "city",
    headerName: "City",
    type: "number",
    width: 120,
  },
  {
    field: "year",
    headerName: "Year",
    type: "number",
    width: 100,
  },

  {
    field: "state",
    headerName: "State",
    width: 120,
  },
  {
    field: "branch",
    headerName: "Branch",
    width: 120,
  },
];
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Orders({ data }) {
  //   console.log(data.data);
  return (
    <div style={{ height: "85vh", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        dense
      />
    </div>
  );
}
