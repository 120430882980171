import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ecell.org.in/">
        KIIT Ecell
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const { email, setEmail } = props;
  const handleData = (data) => {
    let modifiedData = data.map((element) => {
      const sample = {
        roll: 1828043,
        id: "60926563c841191d9d1fd59f",
        name: "Abhishek raj",
        email: "1828043@kiit.ac.in",
        no: 9777925929,
        gender: "M",
        branch: "csse",
        cgpa: 9.1,
        city: "Patna",
        resume: "nahi hai kya kare?",
        state: "Bihar",
        videoResume: "ye kya hota hai",
        year: 4,
        domain: "Tech",
      };
      console.log(element.userId[0].branch);
      sample["domain"] = element.domain;
      sample.roll = element.userId[0].roll;
      sample.id = element.userId[0]._id;
      sample.email = element.userId[0].email;
      sample.gender = element.userId[0].gender;
      sample.branch = element.userId[0].branch;
      sample.cgpa = element.userId[0].cgpa;
      sample.city = element.userId[0].city;
      sample.no = element.userId[0].no;
      sample.name = element.userId[0].name;

      sample.resume = element.userId[0].resume;
      sample.state = element.userId[0].state;
      sample.videoResume = element.userId[0].videoResume;
      sample.year = element.userId[0].year;
      //😣

      return sample;
    });
    props.setData(modifiedData);
    console.log(modifiedData);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    const url2 = "http://localhost:3000/api/auth/student_list";
    const URL = "https://backend.ecell.org.in/api/auth/student_list";
     
    axios
      .post(URL, { pocMail: props.email })
      .then((res) => {
       const imageIncomming=res.data.image_url.split('/d/')[1];
       const imgURL ="https://drive.google.com/uc?id="+ imageIncomming;
       const finalURL = imgURL.replace("/view?usp=sharing","")
        if (res.status === 200) {
          // SetUserDate(res.data);
          handleData(res.data.applied_student);
          props.setCData({
            name: res.data.company_name,
            // url: `https://icamp.ecell.org.in/${res.data.image_url}`,
            url: `${finalURL}`,
          });
        }
      })
      .then((res) => {
        //window.location.href = '/dashboard';
        history.push("/dashboard");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          swal(
            "email not found",
            "please enter email with which you registered",
            "warning"
          );
        } else {
          swal("server error", "please contact admin", "warning");
        }
      });
  };
  const history = useHistory();
  return (
    <Container
      component="main"
      maxWidth="xs"
      // style={{
      // 	diaplay: 'flex',
      // 	justifyContent: 'center',
      // 	alignItems: 'center',
      // }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please enter email Address
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSignIn}
          >
            Let's Hire
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
