import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
import { Avatar } from "@material-ui/core";
import iCampLogo from "./logos.png";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits(props) {
  const classes = useStyles();
  console.log(props.cData);

  // useEffect(() => {

  //   return () => {

  //   }
  // }, [])

  return (
    <React.Fragment
      styles={{ display: "flex", alignItem: "center", position: "relative" }}
    >
      <div className="logoMainDiv">
        <img
          src={iCampLogo}
          alt="ICampLogo"
          //   style={{
          // width: "250px",
          // height: "80px",
          // position: "fixed",
          // top: "50px",
          // left: "140px",
          //   }}
        />
      </div>
      <Typography component="p" variant="h3">
        {props.cData.name}
      </Typography>
      <br />
      <div>
        <img
          src={props.cData.url}
          alt="Company Logo"
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
          }}
        />
      </div>

      {/* <Typography color="textSecondary" className={classes.depositContext}> */}
      {/* Total Registration = {props.cData.name} */}
      {/* <Typography component="p" variant="h5"> */}
      {/* </Typography> */}
      {/* </Typography> */}
      {/* <Typography color="textSecondary" className={classes.depositContext}>
				icamp = {data != undefined ? data.icamp : ''}
			</Typography>
			<Typography color="textSecondary" className={classes.depositContext}>
				imps = {data != undefined ? data.imps : ''}
			</Typography> */}
    </React.Fragment>
  );
}
