import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MainListItems, SecondaryListItems } from './ListItem';
// import Chart from "./Chart";
// import Deposits from "./Deposits";
import Orders from './Orders';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import CompanyInfo from './CompanyInfo';
function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://ecell.org.in/">
				KIIT ECELL
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
}));

export default function Dashboard(props) {
	const { email } = props;
	// const handleData = (data) => {
	// 	// const sample = {
	// 	// 	roll: 1828043,
	// 	// 	_id: '60926563c841191d9d1fd59f',
	// 	// 	name: 'Abhishek raj',
	// 	// 	email: '1828043@kiit.ac.in',
	// 	// 	no: 9777925929,
	// 	// 	gender: 'M',
	// 	// 	branch: 'csse',
	// 	// 	cgpa: 9.1,
	// 	// 	city: 'Patna',
	// 	// 	resume: 'nahi hai kya kare?',
	// 	// 	state: 'Bihar',
	// 	// 	videoResume: 'ye kya hota hai',
	// 	// 	year: 4,
	// 	// 	domain: 'Tech',
	// 	// };
	// 	let modifiedData = data.map((element) => {
	// 		const sample = {
	// 			roll: 1828043,
	// 			id: '60926563c841191d9d1fd59f',
	// 			name: 'Abhishek raj',
	// 			email: '1828043@kiit.ac.in',
	// 			no: 9777925929,
	// 			gender: 'M',
	// 			branch: 'csse',
	// 			cgpa: 9.1,
	// 			city: 'Patna',
	// 			resume: 'nahi hai kya kare?',
	// 			state: 'Bihar',
	// 			videoResume: 'ye kya hota hai',
	// 			year: 4,
	// 			domain: 'Tech',
	// 		};
	// 		console.log(element.userId[0].branch);
	// 		sample['domain'] = element.domain;
	// 		sample.roll = element.userId[0].roll;
	// 		sample.id = element.userId[0]._id;
	// 		sample.email = element.userId[0].email;
	// 		sample.gender = element.userId[0].gender;
	// 		sample.branch = element.userId[0].branch;
	// 		sample.cgpa = element.userId[0].cgpa;
	// 		sample.city = element.userId[0].city;
	// 		sample.no = element.userId[0].no;
	// 		sample.name = element.userId[0].name;

	// 		sample.resume = element.userId[0].resume;
	// 		sample.state = element.userId[0].state;
	// 		sample.videoResume = element.userId[0].videoResume;
	// 		sample.year = element.userId[0].year;
	// 		//😣

	// 		return sample;
	// 	});
	// 	props.setData(modifiedData);
	// 	console.log(modifiedData);
	// };

	// React.useEffect(() => {
	// 	const URL = 'https://icamp.ecell.org.in/api/auth/student_list';

	// 	axios.post(URL, { pocMail: props.email }).then((res) => {
	// 		if (res.status === 200) {
	// 			// SetUserDate(res.data);
	// 			handleData(res.data);
	// 		}
	// 	});
	// }, []);
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	if (email === '') {
		return <Redirect to="/login" />;
	} else {
		return (
			<div style={{"background":"radial-gradient(circle, rgba(255,253,253,1) 0%, rgba(49,193,251,1) 100%)"}} className={classes.root}>
				<CssBaseline />
				{/* <AppBar
					position="absolute"
					className={clsx(classes.appBar, open && classes.appBarShift)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							Dashboard
						</Typography>
						<IconButton color="inherit">
							<Badge badgeContent={4} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List>{MainListItems}</List>
					<Divider />
					<List>{SecondaryListItems}</List>
				</Drawer> */}
				<main className={classes.content} >
					<div className={classes.appBarSpacer} />
					<Container maxWidth="lg" className={classes.container} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
						<Grid container style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
							{/* Chart */}
							{/* <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart  data={userData}/>
              </Paper>
            </Grid> */}
							{/* Recent Deposits */}
							<Grid item xs={12} md={12} lg={12} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
								<Paper className={fixedHeightPaper} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
									<CompanyInfo cData={props.cData} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}} />
								</Paper >
							</Grid>

							<Grid item xs={12} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
								<Paper className={classes.paper} style={{"background":"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)"}}>
									<Orders data={props.data} />
								</Paper>
							</Grid>
						</Grid>
						<Box pt={4}>
							<Copyright />
						</Box>
					</Container>
				</main>
			</div>
		);
	}
}
